import http from './http/index';

export interface SearchResult {
    id: string;
    type: 'user' | 'group' | 'target';
    name: string;
    email?: string;
}

const UniversalSearchAPI = {
    search: async (query: string, type: 'all' | 'users' | 'groups' | 'targets' = 'all') => {
        return http
            .get<SearchResult[]>(`/admin/universal-search?query=${query}&type=${type}`)
            .then((res) => res.data);
    },
};

export default UniversalSearchAPI;

import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

interface Props {
    children?: JSX.Element;
    darkMode: 'light' | 'dark' | 'system';
    setDarkMode: (mode: 'light' | 'dark' | 'system') => void;
}

const SidebarLayout: FC<Props> = ({ children, darkMode, setDarkMode }) => {
    return (
        <div className={`app-container ${darkMode === 'dark' ? 'dark-mode' : ''}`}>
            <Header darkMode={darkMode} setDarkMode={setDarkMode} />
            <div className="main-content">
                <Sidebar darkMode={darkMode} setDarkMode={setDarkMode} />
                <div className="content-area">
                    <Outlet />
                    {children}
                </div>
            </div>
        </div>
    );
};

export default SidebarLayout;
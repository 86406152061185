export const ENTITIES_LIMIT = 25;
export const DEFAULT_LIMIT = 25;
export const DEFAULT_PAGE = 1;

export interface Pagination {
    page: number;
    limit: number;
    sort?: string;
    direction?: 'asc' | 'desc';
}

export interface PaginationMeta {
    currentPage: number;
    limit: number;
    totalPages: number;
    totalCount: number;
}
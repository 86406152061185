import { useEffect } from 'react';

// Custom hook for setting the page title with a suffix
const usePageTitle = (title: string) => {
  useEffect(() => {
    document.title = `${title} | Aptive Index`;
  }, [title]);
};

export default usePageTitle;

// src/services/linkSettingsAPI.ts
import http from "./http/index";
import { LinkNotification, LinkSettings,SendToAssessmentTaker } from '../types/linkSettings';

const LinkSettingsAPI = {
    getLinkSettingsByGroupId: async (groupId: string) => {
        return http
            .get<LinkSettings>(`/admin/link-settings/group/${groupId}`)
            .then((res) => res.data);
    },

    getLinkSettingsByTargetId: async (targetId: string) => {
        return http
            .get<LinkSettings>(`/admin/link-settings/target/${targetId}`)
            .then((res) => res.data);
    },

    getDefaultLinkSettings: async () => {
        return http
            .get<LinkSettings>(`/admin/link-settings/default`)
            .then((res) => res.data);
    },

    updateLinkSettingsByGroupId: async (groupId: string, updatedSettings: {
        notifications: LinkNotification[];
        scoringType: string;
        source: string;
        sendToAssessmentTaker: SendToAssessmentTaker;
    }) => {
        return http
            .put<LinkSettings>(`/admin/link-settings/group/${groupId}`, updatedSettings)
            .then((res) => res.data);
    },

    updateLinkSettingsByTargetId: async (targetId: string, updatedSettings: {
        notifications: LinkNotification[];
        scoringType: string;
        source: string;
        sendToAssessmentTaker: SendToAssessmentTaker;
    }) => {
        return http
            .put<LinkSettings>(`/admin/link-settings/target/${targetId}`, updatedSettings)
            .then((res) => res.data);
    },

    updateEntityDefaultLinkSettings: async (updatedSettings: {
        notifications: LinkNotification[];
        sendToAssessmentTaker: SendToAssessmentTaker;
    }) => {
        try {
            const response = await http.put<LinkSettings>(
                `/admin/link-settings/entity`, 
                updatedSettings
            );
            return response.data;
        } catch (error) {
            console.error('Error updating link settings:', error);
            throw error;
        }
    },
};

export default LinkSettingsAPI;
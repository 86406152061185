import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import useOutsideClick from '../hooks/useOutsideClick';
import NavigationLinks from './NavigationLinks';
import { ReactComponent as Logo } from '../assets/logo-aptive-index-white-square.svg';
import Avatar from '../assets/avatar-placeholder.svg';
import { MagnifyingGlass, Sun, Moon, Desktop, CaretLeft } from "@phosphor-icons/react";
import { ReactComponent as CaretDownIcon } from '../assets/caret-down.svg';
import { ReactComponent as CaretUpIcon } from '../assets/caret-up.svg';
import { ReactComponent as DashboardIcon } from '../assets/icons/icon-dashboard.svg';
import { ReactComponent as PeopleIcon } from '../assets/icons/icon-people.svg';
import { ReactComponent as TargetsIcon } from '../assets/icons/icon-position.svg';
import { ProfileIcon } from '../assets/profiles/ProfileIcons';
import UniversalSearchBar from './UniversalSearchBar';
import { SearchResult } from '../services/universalSearch';
import http from '../services/http';
import { isViewOwnProfile } from '../utils/isViewOwnProfile';

interface SearchButtonProps {
    className?: string;
    onClick?: () => void;
}

const SearchButton: React.FC<SearchButtonProps> = ({ className, onClick }) => (
    <button className={className} onClick={onClick} aria-label="Search">
        <MagnifyingGlass size={24} />
    </button>
);

interface HeaderProps {
    darkMode: 'light' | 'dark' | 'system';
    setDarkMode: (mode: 'light' | 'dark' | 'system') => void;
}

function Header({ darkMode, setDarkMode }: HeaderProps) {
    const oldUser = localStorage.getItem('oldUser');
    const oldUserDetails = oldUser ? (() => {
        try {
            return JSON.parse(oldUser as string);
        } catch (error) {
            console.error('Error parsing oldUser:', error);
            return null;
        }
    })() : null;
    const [width] = useWindowSize();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const profileContainerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [scrollDirection, setScrollDirection] = useState<'down' | 'up' | null>(null);
    const [lastScrollY, setLastScrollY] = useState(0);
    const headerRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);
    const [shouldFocusSearch, setShouldFocusSearch] = useState(false);
    const [isThemeOptionsVisible, setIsThemeOptionsVisible] = useState(false);

    const toggleSearchVisibility = () => {
        setIsSearchVisible(prev => !prev);
        setShouldFocusSearch(true);
    };

    useEffect(() => {
        if (isSearchVisible && shouldFocusSearch && searchRef.current) {
            searchRef.current.focus();
            setShouldFocusSearch(false);
        }
    }, [isSearchVisible, shouldFocusSearch]);

    useOutsideClick(profileContainerRef, () => {
        if (isDropdownVisible) setIsDropdownVisible(false);
    });

    const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
    const handleLogout = () => {
        localStorage.removeItem('user');
    };
    const handleProfileClick = () => navigate('/profile');
    const handleNotificationClick = () => navigate('/notifications');

    const isMac = () => {
        return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    };
    const placeholderText = isMac() ? "Search ⌘ + K" : "Search Ctrl + K";

    const userDetail = localStorage.getItem('user') ? (() => {
        try {
            return JSON.parse(localStorage.getItem('user') || "{}");
        } catch (error) {
            console.error('Error parsing user:', error);
            return {};
        }
    })() : {};

    const userProfile = userDetail?.user?.profileName;
    const isStandartUser = userDetail?.user?.role === "Standard User";

    const handleUniversalSearch = (result: SearchResult | null) => {
        setIsSearchVisible(false);
        if (result) {
            switch (result.type) {
                case 'user':
                    navigate(`/people/${result.id}`);
                    break;
                case 'group':
                    navigate(`/folders/${result.id}`);
                    break;
                case 'target':
                    navigate(`/positions/${result.id}`);
                    break;
                default:
                    console.error('Unknown result type:', result.type);
            }
        }
    };

    const handleSwitchBack = () => {
        const oldUser = localStorage.getItem("oldUser") as string;
        try {
            const user = JSON.parse(oldUser);
            localStorage.setItem("user", oldUser);
            http.setAuthToken(user.token);
            localStorage.removeItem("oldUser");
            navigate('/dashboard');
            navigate(0);
        } catch (error) {
            console.error('Error parsing oldUser:', error);
        }
    };

    const handleScroll = useCallback(() => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY && currentScrollY > 50) {
            setScrollDirection('down');
        } else if (currentScrollY < lastScrollY || currentScrollY <= 50) {
            setScrollDirection('up');
        }
        setLastScrollY(currentScrollY);
    }, [lastScrollY]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        if (headerRef.current) {
            if (scrollDirection === 'down') {
                headerRef.current.classList.add('hidden');
                headerRef.current.classList.remove('visible');
            } else if (scrollDirection === 'up') {
                headerRef.current.classList.add('visible');
                headerRef.current.classList.remove('hidden');
            }
        }
    }, [scrollDirection]);

    const toggleThemeOptions = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsThemeOptionsVisible(!isThemeOptionsVisible);
    };

    const handleThemeChange = (mode: 'light' | 'dark' | 'system') => {
        setDarkMode(mode);
        setIsThemeOptionsVisible(false);
        setIsDropdownVisible(false);  // Close the entire dropdown
    };

    useEffect(() => {
        const closeThemeOptions = () => setIsThemeOptionsVisible(false);
        document.addEventListener('click', closeThemeOptions);
        return () => document.removeEventListener('click', closeThemeOptions);
    }, []);

    const forceShowHeader = useCallback(() => {
        setScrollDirection('up');
        if (headerRef.current) {
            headerRef.current.classList.add('visible');
            headerRef.current.classList.remove('hidden');
        }
    }, []);

    const viewOnlyProfile = isViewOwnProfile()

    return (
        <>
            <header className="app-header" ref={headerRef}>
                <div className="mobile-logo">
                    <Link to="/">
                        <Logo className="logo" />
                    </Link>
                </div>

                {(width > 768 && !viewOnlyProfile) && (
                    <div className="search-bar-container">
                        <UniversalSearchBar
                            onFocus={forceShowHeader}
                            onBlur={() => { }}
                            className="header-search"
                            onResultSelect={handleUniversalSearch}
                            placeholder={placeholderText}
                        />
                    </div>
                )}

                <div className="header-profile-container" ref={profileContainerRef} onClick={toggleDropdown}>
                    {userProfile ? (
                        <ProfileIcon
                            profileName={userProfile}
                            className="header-profile-avatar"
                        />
                    ) : (
                        <img src={Avatar} alt="Profile" className="header-profile-avatar" />
                    )}
                    {isDropdownVisible ? <CaretUpIcon className="caret-icon" style={{ fill: 'white' }} /> :
                        <CaretDownIcon className="caret-icon" style={{ fill: 'white' }} />}
                    {isDropdownVisible && (
                        <ul className="header-profile-dropdown">
                            {
                                !viewOnlyProfile
                                    ? <li><a href='#/' onClick={handleProfileClick}>My Profile</a></li>
                                    : null
                            }
                            <li className={`dark-mode-dropdown ${isThemeOptionsVisible ? 'active' : ''}`} onClick={toggleThemeOptions}>
                                <CaretLeft size={16} className="theme-caret" /> Theme
                                {isThemeOptionsVisible && (
                                    <div className="theme-options" onClick={(e) => e.stopPropagation()}>
                                        <button
                                            onClick={(e) => { e.stopPropagation(); handleThemeChange('light'); }}
                                            className={darkMode === 'light' ? 'active' : ''}
                                        >
                                            <Sun size={20} /> Light
                                        </button>
                                        <button
                                            onClick={(e) => { e.stopPropagation(); handleThemeChange('dark'); }}
                                            className={darkMode === 'dark' ? 'active' : ''}
                                        >
                                            <Moon size={20} /> Dark
                                        </button>
                                        <button
                                            onClick={(e) => { e.stopPropagation(); handleThemeChange('system'); }}
                                            className={darkMode === 'system' ? 'active' : ''}
                                        >
                                            <Desktop size={20} /> System
                                        </button>
                                    </div>
                                )}
                            </li>

                            {(userDetail?.user?.isGodModeUser || userDetail?.user?.role === "Super Admin") &&
                                <li><a href="/settings" onClick={handleNotificationClick}>Org Settings</a></li>
                            }
                            <li><a href="/login" onClick={handleLogout}>Log Out</a></li>
                            {oldUserDetails && (
                                <li>
                                    <button className="button button-primary"
                                        onClick={handleSwitchBack}>{`Back to ${oldUserDetails.user.firstName}'s account`}</button>
                                </li>
                            )}

                        </ul>
                    )}
                </div>

                {isMenuOpen && width <= 768 && (
                    <div className="mobile-navigation">
                        <NavigationLinks
                            setIsMenuOpen={setIsMenuOpen}
                            handleLogout={handleLogout}
                            darkMode={darkMode}
                            setDarkMode={setDarkMode}
                        />
                        {oldUserDetails && (
                            <div className='mobile-assumed-entity'>
                                <button className="button button-primary" onClick={handleSwitchBack}>
                                    {`Back to ${oldUserDetails.user.firstName}'s account`}
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </header>

            {width <= 768 && (
                <div className="app-footer">
                    <div className="mobile-bottom-icons">
                        <NavLink
                            to="/dashboard"
                            className={({ isActive }) => (isActive && !isSearchVisible && !isMenuOpen ? 'active' : 'inactive')}
                            onClick={() => setIsSearchVisible(false)}
                        >
                            <DashboardIcon className="mobile-nav-icon" />
                        </NavLink>
                        {(userDetail?.user?.permissions?.people || userDetail?.user?.role === "Super Admin") &&
                            <NavLink
                                to="/people"
                                className={({ isActive }) => (isActive && !isSearchVisible && !isMenuOpen ? 'active' : 'inactive')}
                                onClick={() => setIsSearchVisible(false)}
                            >
                                <PeopleIcon className="mobile-nav-icon" />
                            </NavLink>
                        }
                        {
                            !viewOnlyProfile
                                ? <SearchButton
                                    className={`magnifying-glass-icon ${isSearchVisible ? 'active' : ''}`}
                                    onClick={toggleSearchVisibility}
                                />
                                : null
                        }
                        {(userDetail.user.permissions.positionsTargets || userDetail.user.role === "Super Admin") &&
                            <NavLink
                                to="/positions"
                                className={({ isActive }) => (isActive && !isSearchVisible && !isMenuOpen ? 'active' : 'inactive')}
                                onClick={() => setIsSearchVisible(false)}
                            >
                                <TargetsIcon className="mobile-nav-icon" />
                            </NavLink>
                        }

                        <button
                            className={`hamburger-menu ${isMenuOpen ? 'active' : ''}`}
                            onClick={() => {
                                setIsMenuOpen(!isMenuOpen);
                                setIsSearchVisible(false);
                            }}
                        >
                            <span className="material-icons hamburger-icon">menu</span>
                        </button>
                    </div>
                </div>
            )}

            {width <= 768 && isSearchVisible && (
                <div
                    className={`search-bar-overlay ${isSearchVisible ? 'visible' : ''}`}
                    onClick={() => setIsSearchVisible(false)}
                >
                    <div className="search-bar-container" onClick={(e) => e.stopPropagation()}>
                        <UniversalSearchBar
                            ref={searchRef}
                            onFocus={() => { }}
                            onBlur={() => { }}
                            className="header-search-mobile"
                            onResultSelect={handleUniversalSearch}
                            placeholder="Search..."
                            isMobile={true}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default Header;

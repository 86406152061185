import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Comment out Microsoft SSO-related code
// import { PublicClientApplication, EventType } from '@azure/msal-browser';
// import { msalConfig } from '../src/MSAuth/authConfig';

// const msalInstance = new PublicClientApplication(msalConfig);

// // Default to using the first account if no account is active on page load
// if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//     // Account selection logic is app dependent. Adjust as needed for different use cases.
//     const activeAccount = msalInstance.getActiveAccount();
//     msalInstance.setActiveAccount(activeAccount);
// }

// // Listen for sign-in event and set active account
// msalInstance.addEventCallback((event: any) => {
//     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//         const account = event.payload.account;
//         msalInstance.setActiveAccount(account);
//     }
// });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement );
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// 


import React, { Dispatch, SetStateAction } from 'react'

interface SuccessMessageModalProps {
    isOpen: boolean;
    heading: string;
    message: string;
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

const ErrorMessageModal: React.FC<SuccessMessageModalProps> = ({ isOpen, setIsOpen, heading, message }) => {

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="new-item-modal-content">

                <h2>{heading}</h2>
                <p>{message}</p>
                <button
                    className="button button-cancel"
                    onClick={() => {
                        setIsOpen(false);
                    }}
                >
                    Close
                </button>

            </div>
        </div>

    );
};

export default ErrorMessageModal;




// src/components/LoginForm.js
import React, { useState } from 'react';
import ColorLogo from '../assets/logo-aptive-index-color-square.svg';
import '../styles/login.css';
import usePageTitle from '../hooks/usePageTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Auth from '../services/auth';
import ErrorMessageModal from '../common/ErrorMessageModal';

interface FormInput {
    email: string
}

function PasswordForgetForm({ onLogin }: any) {
    usePageTitle("Forget Password");

    const validationSchema = yup.object().shape({
        email: yup.string()
            .email('Email is invalid')
            .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Email is invalid')
            .required('Email is required')
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormInput>({ resolver: yupResolver(validationSchema) });

    const [isEmailFocused, setIsEmailFocused] = useState<boolean>(false);
    const [isEmailSend, setIsEmailSend] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    const handleEmailFocus = () => { setIsEmailFocused(true); }
    const handleEmailBlur = () => setIsEmailFocused(false);

    const onSubmit = async (data: FormInput) => {
        //console.log("onSubmit data: ",data, data.email);
        //onLogin(data.email, data.password);

        try {

            setIsSubmitting(true);
            const resp = await Auth.passwordResetToken(data.email);
            console.log(resp);
            if (resp.status === 201) {
                console.log("email is send successfully.");
                setIsEmailSend(true);
            }

        } catch (error: any) {
            // if (error?.response?.status == 400) {
            // console.log("show in dialogue: ", error.response.data);
            setErrorMessage(error.response.data);
            setIsError(true);
            // }
        } finally {
            setIsSubmitting(false);
        }

    };

    // const handlePasswordForget = ()=>{
    //     console.log("hanlde password forget.");
    // }

    return (
        <div className="login-container">
            <div className="login-top-bar">
                <img src={ColorLogo} alt="Aptive Index Logo" className="login-logo" />
            </div>
            <div className="login-main-content">
                <div>
                    <h2 className="login-h2 login-accent-orange">Reset your password</h2>
                    <div className="login-subtitle">Enter your email to get password reset link.
                    </div>
                    {!isEmailSend || <div className="login-subtitle">Please check your email for password reset link.</div>}
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="login-user-info-form">
                <div className="login-input-div">
                    <label className="login-label">Email</label>
                    <input
                        {...register("email")}
                        className={`login-input-email ${isEmailFocused ? 'focused' : ''}`}
                        type="text"
                        onFocus={handleEmailFocus}
                        onBlur={handleEmailBlur}
                    />
                    <p className="invalid-feedback">{errors.email?.message}</p>
                </div>
                <button className="login-submit-button" type="submit" disabled={isSubmitting}>{isSubmitting ? "Sending" : "Send"}</button>
            </form>
            <ErrorMessageModal
                isOpen={isError}
                setIsOpen={setIsError}
                heading='Error'
                message={errorMessage}
            />
        </div>
    );
}

export default PasswordForgetForm;

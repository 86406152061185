import React, { useState, useEffect } from "react";
import "./App.css";
import { jwtDecode } from "jwt-decode";
import MainApp from "./components/mainApp";

function App() {
    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem("user") as string)
    );

    const checkTokenExpiration = () => {
        const userToken = JSON.parse(localStorage.getItem("user") as string)?.token;
        if (!userToken) {
            setUser(null);
            return;
        }

        const decodedToken: any = jwtDecode(userToken) || "";
        const expirationTime = decodedToken?.exp * 1000;
        const currentTime = Date.now();
        const timeUntilExpiration = expirationTime - currentTime;

        if (timeUntilExpiration > 0) {
            setTimeout(() => {
                localStorage.removeItem("user");
                setUser(null);
                window.location.href = "/login"; // Redirect to the login page
            }, timeUntilExpiration);
        } else {
            localStorage.removeItem("user");
            setUser(null);
            window.location.href = "/login"; // Redirect to the login page
        }
        // if user not having permissions, force him to login again.
        const user = JSON.parse(localStorage.getItem("user") || "{}")?.user;
        if (user && user?.permissions?.people === undefined) {
            localStorage.removeItem('user');
            setUser(null);
            window.location.href = "/login";
        }
    };

    // Token expiration check
    useEffect(() => {
        checkTokenExpiration();
    }, []);

    return (
        <MainApp
            user={user}
            setUser={setUser}
        />
    );
}

export default App;
import React from 'react'
import { ReactComponent as IconX } from '../assets/icon-x.svg';

interface confirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    confirm: () => void
    question: string;
}

const ConfirmationModal: React.FC<confirmationModalProps> = ({isOpen, onClose, confirm, question}) => {

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="new-item-modal-content">
                <div className="modal-close-icon modal-close-icon-sm" onClick={onClose}>
                    <IconX/>
                </div>
                <h2>Confirmation</h2>
                <div>{question}</div>
                <button className="button button-primary" type="submit" onClick={confirm}>Yes</button>
                {' '}
                <button className="button button-cancel" type="submit" onClick={onClose}>No</button>
            </div>
        </div>

    );
};

export default ConfirmationModal;
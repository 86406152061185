import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import UniversalSearchAPI, { SearchResult } from '../services/universalSearch';
import { MagnifyingGlass, Briefcase, FolderOpen, User } from "@phosphor-icons/react";

export interface UniversalSearchBarProps {
    onFocus?: () => void;
    onBlur?: () => void;
    className?: string;
    onResultSelect: (result: SearchResult | null) => void;
    placeholder?: string;
    isMobile?: boolean;
}

export interface UniversalSearchBarRef {
    focus: () => void;
}

const UniversalSearchBar = forwardRef<UniversalSearchBarRef, UniversalSearchBarProps>(({
    onFocus,
    onBlur,
    className,
    onResultSelect,
    placeholder = "Search...",
    isMobile = false,
}, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<SearchResult[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const inputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        },
    }));

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
            setIsFocused(false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
            event.preventDefault();
            inputRef.current?.focus();
        }
    };

    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        setIsOpen(true);

        if (value.trim() === '') {
            setOptions([]);
            return;
        }

        try {
            const results = await UniversalSearchAPI.search(value);
            setOptions(results);
        } catch (error) {
            console.error('Error fetching search results:', error);
            setOptions([]);
        }
    };

    const handleSelectResult = (result: SearchResult) => {
        onResultSelect(result);
        setInputValue('');
        setOptions([]);
        setIsOpen(false);
    };

    const handleOptionClick = (e: React.MouseEvent | React.TouchEvent, result: SearchResult) => {
        e.preventDefault();
        e.stopPropagation();
        handleSelectResult(result);
    };

    const handleInputFocus = () => {
        setIsFocused(true);
        setIsOpen(true);
        onFocus?.();
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
            setIsOpen(false);
        }, 200);
        onBlur?.();
    };

    const handleKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => (prevIndex + 1) % options.length);
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length);
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (highlightedIndex >= 0 && highlightedIndex < options.length) {
                handleSelectResult(options[highlightedIndex]);
            }
        } else if (e.key === 'Escape') {
            e.preventDefault();
            setInputValue('');
            setOptions([]);
            setIsOpen(false);
            setHighlightedIndex(-1);
            inputRef.current?.blur();
        }
    };

    const renderResultIcon = (type: string) => {
        switch (type) {
            case 'user':
                return <User className="result-icon" />;
            case 'target':
                return <Briefcase className="result-icon" />;
            case 'group':
                return <FolderOpen className="result-icon" />;
            default:
                return null;
        }
    };

    return (
        <div className={`search-bar ${className || ''} ${isFocused ? 'focused' : ''}`}>
            <div className="search-bar-input-wrapper">
                <input
                    ref={inputRef}
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDownInput}
                    placeholder={placeholder}
                    className="search-bar-input"
                />
                <MagnifyingGlass className="search-bar-icon" />
            </div>
            {isOpen && (
                <div ref={dropdownRef} className="search-bar-dropdown">
                    {options.length > 0 ? (
                        options.map((option, index) => (
                            <div
                                key={option.id}
                                onClick={(e) => handleOptionClick(e, option)}
                                onTouchEnd={(e) => handleOptionClick(e, option)}
                                className={`search-bar-option ${highlightedIndex === index ? 'highlighted' : ''}`}
                            >
                                {renderResultIcon(option.type)}
                                <span className="result-text">{option.name} {option.email && ` - ${option.email}`}</span>
                            </div>
                        ))
                    ) : (
                        <div className="search-bar-no-options">Begin typing to search...</div>
                    )}
                </div>
            )}
        </div>
    );
});

export default UniversalSearchBar;
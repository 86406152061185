import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ColorLogo from '../assets/logo-aptive-index-color-square.svg';
import '../styles/login.css';
import usePageTitle from '../hooks/usePageTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Auth from '../services/auth';
import ErrorMessageModal from '../common/ErrorMessageModal';

interface FormInput {
    newPassword: string;
    confirmPassword: string;
}

function ResetForgetPasswordForm({ onLogin }: any) {
    usePageTitle("Reset Password");

    const validationSchema = yup.object().shape({
        newPassword: yup.string()
            .required("Password is required field")
            .min(6, 'Password must be at least 6 characters')
            .max(40, 'Password must not exceed 40 characters'),
        confirmPassword: yup.string()
            .required("confirm Password is required")
            .oneOf([yup.ref('newPassword')], 'confirm Password must match with New Password')
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormInput>({ resolver: yupResolver(validationSchema) });

    const [isPassFocused, setIsPassFocused] = useState(false);
    const [isConfirmPassFocused, setIsConfirmPassFocused] = useState(false);

    const [token, setToken] = useState<string | null>(null);

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokenFromSearch = searchParams.get('token');
        setToken(tokenFromSearch);
    }, [location.search]);

    const navigate = useNavigate();

    const handlePassFocus = () => setIsPassFocused(true);
    const handlePassBlur = () => setIsPassFocused(false);

    const handleConfirmPassFocus = () => setIsConfirmPassFocused(true);
    const handleConfirmPassBlur = () => setIsConfirmPassFocused(false);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [moveToForgetPasswordForm, setMoveToForgetPasswordForm] = useState<boolean>(false);

    const handleTokenExpire = useCallback(() => {
        // console.log("handle password forget.");
        navigate('/password-forget');
    }, [navigate]);

    const onSubmit = async (data: FormInput) => {
        // console.log("onSubmit data: ", data);
        try {
            if (token) {
                const resp = await Auth.passwordReset(data.newPassword, data.confirmPassword, token);
                // console.log("===================", resp);
                if (resp.status === 201) {
                    console.log("password is changed successfully.");
                    navigate('/');
                }
            } else {
                console.log("token is missing");
            }
        } catch (error: any) {
            if (error?.response?.status === 500) {
                console.log("show in dialogue: ", error.response.data);
                setErrorMessage(error.response.data);
                setIsError(true);
                setMoveToForgetPasswordForm(true);
            }
        }
        // onLogin(data.email, data.password);

    };

    useEffect(() => {
        if (moveToForgetPasswordForm && !isError) {
            handleTokenExpire();
        }
    }, [isError, moveToForgetPasswordForm, handleTokenExpire]);

    return (
        <div className="login-container">
            <div className="login-top-bar">
                <img src={ColorLogo} alt="Aptive Index Logo" className="login-logo" />
            </div>
            <div className="login-main-content">
                <div>
                    <h2 className="login-h2 login-accent-orange">Reset Password</h2>
                    <div className="login-subtitle">Enter new password below to reset your password.
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="login-user-info-form">
                <div className="login-input-div">
                    <label className="login-label">New Password</label>
                    <input
                        {...register("newPassword")}
                        className={`login-input-pass ${isPassFocused ? 'focused' : ''}`}
                        type="password"
                        onFocus={handlePassFocus}
                        onBlur={handlePassBlur}
                    />
                    <p className="invalid-feedback">{errors.newPassword?.message}</p>
                </div>
                <div className="login-input-div">
                    <label className="login-label">Confirm Password</label>
                    <input
                        {...register("confirmPassword")}
                        className={`login-input-pass ${isConfirmPassFocused ? 'focused' : ''}`}
                        type="password"
                        onFocus={handleConfirmPassFocus}
                        onBlur={handleConfirmPassBlur}
                    />
                    <p className="invalid-feedback">{errors.confirmPassword?.message}</p>
                </div>
                <button className="login-submit-button" type="submit">Update</button>
            </form>
            <ErrorMessageModal
                isOpen={isError}
                setIsOpen={setIsError}
                heading='Error'
                message={errorMessage}
            />
        </div>
    );
}

export default ResetForgetPasswordForm;

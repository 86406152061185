import { RefObject, useEffect } from 'react';

const useOutsideClick = (ref:RefObject<HTMLDivElement>, callback: () => void ) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);

    return;
};

export default useOutsideClick;